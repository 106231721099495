
















import {
  PiWizardNavigation,
  PiWizardNavigationButtonEnum,
  WizardNavigationButton
} from "@/components/Wizard/WizardNew/types";

import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({})
export default class PiWizardFooter extends Vue {
  @Prop() navigation!: PiWizardNavigation

  @Emit()
  navigationButtonClick(button: WizardNavigationButton) {
    return button
  }

  buttonTitle(button: WizardNavigationButton) {
    if (button.title) {
      return button.title
    } else {
      return this.$t(`wizard_footer_${ snakeCase(button.type) }`).toString()
    }
  }

  buttonIcon(button: WizardNavigationButton) {
    if (button.icon) {
      return button.icon
    } else if (button.type === PiWizardNavigationButtonEnum.BackButton) {
      return 'arrow-left'
    } else if (button.type === PiWizardNavigationButtonEnum.NextButton) {
      return 'arrow-right'
    } else if (button.type === PiWizardNavigationButtonEnum.CompleteButton) {
      return 'check'
    } else {
      return ''
    }
  }
}
